<template>
    <v-card tile max-height="400" >
        <v-dialog max-width="500" v-model="dialog">
            <section class="mb-3">
                <Login v-if="showLogin" @toRegister="showLogin = false" transition="fade-transition" :toCheckout="true"/>
                <Register v-else-if="!showLogin" @toLogin="showLogin = true" transition="fade-transition"/> 
            </section>
        </v-dialog>
        <v-card-title class="fixed primary-font l-primary--text f16 fw600 pb-0">
            My List
        </v-card-title>
        
        <v-container class="d-flex flex-column text-center pa-10" v-if="courses.length === 0">
            <span class="f12">Your list is empty</span>
            <span v-if="$route.name != 'Browse Courses'" class="secondary--text primary-font f12 fw600 pointer-cursor"  @click="$router.push({name: 'Browse Courses'})">Browse Courses</span>
        </v-container>
        <v-list width="310" height="180" dense style="overflow-y: auto;" v-if="courses.length > 0">
            <v-list-item v-for="item in courses" :key="item.uuid" class="d-flex align-start flex-row my-2">
                    <v-img
                        width="80"
                        height="100%"
                        class="mr-1 pointer-cursor"
                        :src="item.image ? item.image.url : require('@/assets/images/landing/background/default-course-bg.png')"
                        v-on:error="require('@/assets/images/landing/background/default-course-bg.png')"
                        @click="$router.push({name: 'Course Learn More', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})"/>
                    <v-container class="d-flex flex-column py-0 px-0 pointer-cursor" @click="$router.push({name: 'Course Learn More', params: { uuid: item.uuid}, query: { timestamp: Date.now() }})">
                        <section class="primary-font fw600 f12">
                            <div class="truncated break-word">
                                {{ item.title ? item.title : 'Untitled' }}
                                <!-- {{ truncateDescription(item.title, 18) }} -->
                            </div>
                        </section>
                        <span class="l-primary--text f12 fw600" v-if="item.is_free">FREE!</span>
                        <span class="l-primary--text f12 fw600" v-if="!item.is_free">PHP {{ item.price ? item.price : 0 }}</span>
                    </v-container>
                    <v-btn icon dense @click="removeFromCart(item.uuid)" size="10">
                        <v-icon> mdi-close </v-icon>
                    </v-btn>
            </v-list-item>
        </v-list>
        
        <v-card-title class="fixed primary-font l-primary--text f15 fw600 pb-3 text-right" v-if="courses.length > 0">
            Total: Php {{ getPrice }}
        </v-card-title>

        <v-card-title class="d-flex flex-column" v-if="courses.length > 0 && $route.name !== 'User Cart'">
            <v-btn
                outlined
                color="l-primary"
                class=" f11 primary-font mb-2"
                dense
                block
                @click="dialog = true">
                Go to Checkout
            </v-btn>
            <v-btn
                text
                class=" f11 primary-font"
                dense
                @click="()=>{
                    this.$emit('close')
                    $router.push({name: 'User Cart'})
                }"
                block>
                Go to List
            </v-btn>
        </v-card-title>
    </v-card>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import Login from './forms/Login.vue'
import Register from './forms/Register.vue'

export default {
    name: 'cart-menu',
    components: {
        Login,
        Register
    },
    data: () => ({
        loading: false,
        dialog: false,
        showLogin: false
    }),
    computed: {
        ...mapState({
            courses: (state) => state.cart
        }),

        getPrice() {
            if(this.courses.length === 0) {
                return 0
            } else {
                let price = 0
                this.courses.forEach(i => {
                    if(!i.is_free) {
                        price += parseInt(i.price ? i.price : 0)
                    }
                })
                return price
            }
        }
    },
    methods: {
        ...mapMutations(['cartMutation']),

        truncateDescription(desc, length) {
            return desc.length > length ? `${desc.slice(0, length-4)}...` : desc
        },

        removeFromCart(uuid){
            let filtered = this.courses.filter(i => i.uuid !== uuid)
            this.cartMutation(filtered)
            if(filtered.length > 0) {
                localStorage.setItem('cart', JSON.stringify(filtered))
            } else {
                localStorage.removeItem('cart')
            }
            
        }
    }
}
</script>